<template>
    <div class="mx-4 mt-4">
        <v-card class="elevation-5">
            <v-card-title>
                {{ 'Asosiy Vositalar' }}

                <!--                <v-btn-->
                <!--                    @click="-->
                <!--            getDetailExcel(1); inventory_excel = [];"-->
                <!--                    style="background-color:blue; color: white; margin-right: 10px"-->
                <!--                    class="ml-8"-->
                <!--                >-->
                <!--                    Ecelga yuklash-->
                <!--                </v-btn>-->

<!--                <v-btn-->
<!--                    @click="generatePDF"-->
<!--                    style="background-color: blue; color: white; margin-right: 10px"-->
<!--                    class="ml-2"-->
<!--                >-->
<!--                    Generate PDF-->
<!--                </v-btn>-->
                <v-btn
                    @click="getDetailExcel(1); inventory_excel = [];"
                    style="background-color:green; color: white; margin-right: 10px"
                    class="ml-2"
                >
                    Excelga yuklash
                </v-btn>
                <v-btn
                    @click="writeToExcel(1)"
                    style="background-color:saddlebrown; color: white; margin-right: 10px"
                    class="ml-1"
                >
                    Slichitelniy Ombor
                </v-btn>
                <v-btn
                    @click="writeToExcelGeneral(1)"
                    style="background-color:gray; color: white; margin-right: 10px"
                    class="ml-2"
                >
                    Slichitelniy Umumiy
                </v-btn>
                <v-btn
                    @click="countingNoteExcel(1)"
                    style="background-color:Orange; color: white; margin-right: 10px"
                    class="ml-2"
                >
                    Sanoq Qaydnomasi
                </v-btn>
                <v-btn
                    @click="countingNoteExcelGeneral(1)"
                    style="background-color:Brown; color: white; margin-right: 10px"
                    class="ml-2"
                >
                    Umumiy Sanoq Qaydnomasi
                </v-btn>
<!--                <v-text-field-->
<!--                    v-model="search"-->
<!--                    append-icon="mdi-magnify"-->
<!--                    :label="$t('search')"-->
<!--                    single-line-->
<!--                    hide-details-->
<!--                    clearable-->
<!--                    @keyup.native.enter="getProductList"-->
<!--                    outlined-->
<!--                    dense-->
<!--                ></v-text-field>-->

            </v-card-title>




            <v-data-table
                :headers="headers"
                :items="vehicles"
                :height="screenHeight"
                class="elevation-1 ma-1"
                :search="search"
                style="border: 1px solid #aaa"
                fixed-header
                single-expand
                :options.sync="dataTableOptions"
                item-key="id"
                :server-items-length="server_items_length"
                :disable-pagination="true"
                :footer-props="{
          itemsPerPageText: 'Sahifadagi yozuvlar soni',
          itemsPerPageOptions: [50, 100, 200],
          showFirstLastPage: true,
        }"
                @update:page="updatePage"
                @update:items-per-page="updatePerPage"
            >

                <template v-slot:body.prepend >
                    <tr class="py-0 my-0 filter-input" >
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa">
                            <input
                                v-model="filterForm.inventarNo"
                                @keyup.enter="getProductList()"
                                style="border: 1px solid #aaa"
                            />
                        </td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa">
                            <input
                                v-model="filterForm.responsibleDivision"
                                @keyup.enter="getProductList()"
                                style="border: 1px solid #aaa"
                            />
                        </td>
                        <td class="pa-1" style="border: 1px solid #aaa">
                            <input
                                v-model="filterForm.responsiblePerson"
                                @keyup.enter="getProductList()"
                                style="border: 1px solid #aaa"
                            />
                        </td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa">
                            <input
                                v-model="filterForm.accountByGroup"
                                @keyup.enter="getProductList()"
                                style="border: 1px solid #aaa"
                            />
                        </td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>
                        <td class="pa-1" style="border: 1px solid #aaa"></td>

                        <!-- <td class="pa-1"></td> -->
                    </tr>
                </template>

                <template v-slot:item.id="{ item }">{{
                        vehicles.map((v) => v.id).indexOf(item.id) + from
                    }}
                </template>

<!--                <template v-slot:item.options="{ item }">-->
<!--                    <v-btn @click="generateQRCode(item.id)" color="primary">Generate QR Code</v-btn>-->
<!--                </template>-->
                <template v-slot:item.options="{ item }"
                >
                    <v-icon color="primary" @click="editDetail(item)"
                    >mdi-pencil sa
                    </v-icon
                    >
                </template>
            </v-data-table>
            <v-dialog v-model="downloadExcel" hide-overlay persistent width="300">
                <v-card>
                    <v-card-text class="py-1 px-3">
                        <v-btn
                            color="success"
                            class="mx-10"
                            @click="downloadExcel = false"
                            text
                        >
                            <download-excel
                                :data="application_excel"
                                :name="'Arizalar_ruyxati.xls'"
                            >
                                <span style="color: #4caf50">{{ $t("download") }}</span>
                                <v-icon color="success" height="20">mdi-download</v-icon>
                            </download-excel>
                        </v-btn>
                        <v-btn class color="error" @click="downloadExcel = false" icon>
                            <v-icon color="error" height="20">mdi-close</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>


            <v-dialog
                v-model="DetailModal"
                persistent
                max-width="50%"
                @keydown.esc="DetailModal = false"
            >
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ $t('Edit Detail') }}</span>
                        <v-spacer></v-spacer>
                        <v-btn color="red" x-small fab class @click="DetailModal = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col class="pt-0" cols="12">
                                    <label>{{ 'responsiblePerson' }}</label>
                                    <v-text-field v-model="form.sklad" dense></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pt-0" cols="12">
                                    <label>{{ 'responsibleDivision' }}</label>
                                    <v-text-field v-model="form.responsoblePerson" dense></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pt-0" cols="12">
                                    <label>{{ 'accountByGroup' }}</label>
                                    <v-text-field v-model="form.account" dense></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn color="green" dark @click="save">{{ $t('save') }}</v-btn>
                        <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="loading" width="300" hide-overlay>
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ $t('loadingText') }}
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>
<script>

import jsPDF from 'jspdf';
import QRCode from 'qrcode-generator';
import Swal from "sweetalert2";
// import JsBarcode from 'jsbarcode';
let axios = require("axios").default;
export default {
    data() {
        return {
            loading: false,
            vehicles: [],
            search: '',
            VehicleModal: false,
            form: {},
            application_excel: [],
            dataTableOptions: {
                page: 1,
                itemsPerPage: 50,
            },
            page: 1,
            server_items_length: -1,
            from: 0,
            downloadExcel: false,
            qrCodeSize: 60,

            filterForm: {
                inventarNo: null,
                responsibleDivision: null,
                responsiblePerson: null,
                accountByGroup: null,
            },
            DetailModal: false,
            Products:[],
        };
    },
    computed: {
        headers() {
            return [
                {text: this.$t('ID'), value: 'id', sortable: false},
                {text: this.$t('Excel ID'), value: 'exID', sortable: false},
                {text: this.$t('Inventar No'), value: 'inventarN', sortable: false},
                {text: this.$t('QRString'), value: 'qrString', sortable: false},
                {text: this.$t('Responsible Division'), value: 'sklad', sortable: false},
                {text: this.$t('Responsible Person'), value: 'responsoblePerson', sortable: false},
                {text: this.$t('Name'), value: 'name', sortable: false},
                {text: this.$t('Additional Note'), value: 'additionalNote', sortable: false},
                {text: this.$t('Unit Measure'), value: 'unitMeasure', sortable: false},
                {text: this.$t('Commissioning Date'), value: 'commissioningDate', sortable: false},
                {text: this.$t('account By Group'), value: 'account', sortable: false},
                {text: this.$t('Quantity'), value: 'quantity', sortable: false},
                {text: this.$t('Initial Cost UZS'), value: 'initialCostUZS', sortable: false},
                {text: this.$t('Initial Cost USD'), value: 'initialCostUSD', sortable: false},
                {text: this.$t('Checked'), value: 'checked', sortable: false},
                // {
                //     text: 'Barcode',
                //     align: 'center',
                //     value: 'options',
                //     sortable: false,
                //     width: 80,
                // },
                {
                    text: 'Amallar',
                    align: 'center',
                    value: 'options',
                    sortable: false,
                    width: 80,
                },
            ];
        },
        screenHeight() {
            return window.innerHeight - 210;
        },
    },
    methods: {
        getProductList() {
            this.loading = true;
            this.$axios
                .post(this.$store.state.backend_url + '/api/getProducts', {
                    filter: this.filterForm,
                    pagination: this.dataTableOptions,
                    search: this.search,
                })
                .then((res) => {
                    this.server_items_length = res.data.total;
                    this.from = res.data.from;
                    this.vehicles = res.data.data;
                    this.loading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.loading = false;
                });
        },


        updatePage() {
            this.getProductList();
        },
        screenWidth() {
            return window.innerWidth;
        },
        updatePerPage() {
            this.getProductList();
        },


        generateQRCode(id) {
            // Create a QR code with the given data
            const qr = new QRCode(0, 'L');
            qr.addData(id);
            qr.make();
            const qrData = qr.createDataURL(4); // Generate a data URL

            // Create a new PDF
            const pdf = new jsPDF();
            pdf.addImage(qrData, 'PNG', 10, 10, 40, 40); // Adjust the size as needed

            // Save the PDF
            pdf.save(`qrcode_${id}.pdf`);
        },


        getDetailExcel(page) {
            let new_array = [];
            this.loading = true;
            axios
                .post(this.$store.state.backend_url + "/api/products/get-excel", {
                    filter: this.filter,
                    search: this.search,
                    type: 1,
                    pagination: {
                        page: page,
                        itemsPerPage: 1000,
                    },
                })
                .then((response) => {
                    console.log(response.data);

                    response.data.map((v, index) => {
                        console.log(index);
                        new_array.push({
                            "№": index + page,
                            'Объект номи': v.product_name,
                            'Объект киска таснифи': v.short_name,
                            'Фойдаланишга топширилган санаси': v.commissioning_date,
                            'Инвентар': v.inventar_no,
                            'Завод ': v.zavod,
                            'Checked ': v.checked,
                            'CheckedDateTime ': v.checkedDT,
                            'Tabno': v.TabNo,
                            'Мик-дори': v.accounting_quantity,
                            'ResponsibleDivision': v.responsibleDivision,
                            'ResponsiblePerson': v.responsiblePerson,
                        });
                    });
                    this.application_excel = this.application_excel.concat(new_array);
                    if (response.data.length == 1000) {
                        this.getDetailExcel(++page);
                    } else {
                        this.loading = false;
                        this.downloadExcel = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        //   .post(this.$store.state.backend_url + "/api/products/write-to-excel/",
        writeToExcel() {
            this.loading = true;
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            };
            axios
                .post(this.$store.state.backend_url + "/api/products/write-to-excel/",
                    {search: this.filterForm},
                    config
                )
                // .then((response) => {
                //     console.log(response.data);
                // })
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = Date.now() + ".xls";
                    link.click();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        writeToExcelGeneral() {
            this.loading = true;
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            };
            axios
                .post(this.$store.state.backend_url + "/api/products/write-to-excel-general/",
                    {search: this.search},
                    config
                )
                // .then((response) => {
                //     console.log(response.data);
                // })
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = Date.now() + ".xls";
                    link.click();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        countingNoteExcel() {
            this.loading = true;
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            };
            axios
                .post(this.$store.state.backend_url + "/api/products/counting-note-excel/",
                    {search: this.filterForm},
                    config
                )
                // .then((response) => {
                //     console.log(response.data);
                // })
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = Date.now() + ".xls";
                    link.click();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        countingNoteExcelGeneral() {
            this.loading = true;
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            };
            axios
                .post(this.$store.state.backend_url + "/api/products/counting-note-excel-general/",
                    config
                )
                // .then((response) => {
                //     console.log(response.data);
                // })
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = Date.now() + ".xls";
                    link.click();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        generatePDF() {
            const pdf = new jsPDF({unit: 'mm', format: 'a4'});
            const maxQRPerPage = 10;
            let qrCodeCounter = 0;
            let isFirstPageEmpty = true; // Track if the first page is empty

            this.vehicles.forEach((item, index) => {
                if (qrCodeCounter === 0) {
                    if (isFirstPageEmpty) {
                        // Skip adding a page if it's the first page and it's empty
                        isFirstPageEmpty = false; // Mark the first page as not empty
                    } else {
                        pdf.addPage();
                    }
                }

                const qrX = 5 + (qrCodeCounter % 2) * 90;
                const qrY = Math.floor(qrCodeCounter / 2) * 80 - 2;

                const qr = new QRCode(0, 'L');
                qr.addData(`${item.q_r_code.qrString}`);
                qr.make();
                const qrData = qr.createDataURL(4);

                pdf.addImage(qrData, 'PNG', qrX + 40, qrY, 60, 60);
                pdf.setFontSize(12);

                // const MAX_NAME_LENGTH = 11;
                // const truncatedName = item.excel_source.name.substring(0, MAX_NAME_LENGTH).replace(/\s/g, '');
                // const displayName = truncatedName.length >= MAX_NAME_LENGTH ? truncatedName + '...' : truncatedName;

                // pdf.text(qrX + 10, qrY + 25, `ID: ${item.id}`);
                // pdf.text(qrX - 10, qrY + 14, `Inventar No: ${item.excel_source.inventarN}`);
                // pdf.text(qrX - 10, qrY + 35, displayName);

                pdf.text(qrX + 10, qrY + 25, `${item.q_r_code.uniqueN}`);
                pdf.text(qrX + 10, qrY + 14, `${item.excel_source.inventarN}`);

                // Add the truncated name (with ellipsis) to the PDF
                // pdf.text(qrX + 10, qrY + 35, displayName);

                qrCodeCounter++;

                if (qrCodeCounter === maxQRPerPage || index === this.vehicles.length - 1) {
                    const pageHeight = pdf.internal.pageSize.height;
                    const currentPageHeight = pageHeight - pdf.internal.getCoordinateString(10, 10).y;

                    if (currentPageHeight === 0) {
                        pdf.deletePage(pdf.internal.getNumberOfPages());
                    }

                    qrCodeCounter = 0;
                }
            });

            pdf.save('Main_Tools_Qrcode.pdf');
        },

        editDetail(item) {
            this.DetailModal = true;
            this.form = Object.assign({}, item);
        },

        save() {
            this.$axios
                .post(this.$store.state.backend_url + '/api/products/update', this.form)
                .then(() => {
                    this.getProductList();
                    this.Products = [];
                    this.DetailModal = false;
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });
                    Toast.fire({
                        icon: 'success',
                        title: this.$t('create_update_operation'),
                    });
                });
        },


        // generatePDF() {
        //     const pdf = new jsPDF({ unit: 'mm', format: 'a4' });
        //     const maxQRPerPage = 10;
        //     let qrCodeCounter = 0;
        //     let isFirstPageEmpty = true; // Track if the first page is empty
        //
        //     this.vehicles.forEach((item, index) => {
        //         if (qrCodeCounter === 0) {
        //             if (isFirstPageEmpty) {
        //                 // Skip adding a page if it's the first page and it's empty
        //                 isFirstPageEmpty = false; // Mark the first page as not empty
        //             } else {
        //                 pdf.addPage();
        //             }
        //         }
        //
        //         const qrX = 5 + (qrCodeCounter % 2) * 90;
        //         const qrY = Math.floor(qrCodeCounter / 2) * 80 - 2;
        //
        //         const qr = new QRCode(0, 'L');
        //         qr.addData(`${item.q_r_code.qrString}`);
        //         qr.make();
        //         const qrData = qr.createDataURL(4);
        //
        //         pdf.addImage(qrData, 'PNG', qrX + 10, qrY, 25, 25);
        //         pdf.setFontSize(12);
        //
        //         const MAX_NAME_LENGTH = 11;
        //         const truncatedName = item.excel_source.name.substring(0, MAX_NAME_LENGTH).replace(/\s/g, '');
        //         const displayName = truncatedName.length >= MAX_NAME_LENGTH ? truncatedName + '...' : truncatedName;
        //
        //         // Add the truncated name (with ellipsis) to the PDF, moved to the right by 20 pixels
        //         pdf.text(qrX - 18, qrY + 30, displayName);
        //
        //         // Additional text lines
        //         pdf.text(qrX + 1, qrY + 25, `${item.q_r_code.uniqueN}`);
        //         pdf.text(qrX + 1, qrY + 35, `${item.excel_source.inventarN}`);
        //
        //         qrCodeCounter++;
        //
        //         if (qrCodeCounter === maxQRPerPage || index === this.vehicles.length - 1) {
        //             const pageHeight = pdf.internal.pageSize.height;
        //             const currentPageHeight = pageHeight - pdf.internal.getCoordinateString(10, 10).y;
        //
        //             if (currentPageHeight === 0) {
        //                 pdf.deletePage(pdf.internal.getNumberOfPages());
        //             }
        //
        //             qrCodeCounter = 0;
        //         }
        //     });
        //
        //     pdf.save('Main_Tools_Qrcode.pdf');
        // }


    },
    mounted() {
        this.getProductList();
        document.title = this.$t('drawings');
    },
};
</script>
<style>
.itemWidth {
    width: 15%;
}

.dialogHeight {
    max-height: 80%;
}

.itemWidth {
    width: 15%;
}

.dialogHeight {
    max-height: 80%;
}
</style>
